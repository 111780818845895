import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "./../../context/ContextProvider";
// internal
// import menu_data from "./MobileData";



const MobileMenus = () => {
  const [navTitle, setNavTitle] = useState("");
  const { currentHotel } = useContext(AppContext);

  const menu_data = [
    {
      id: 1,
      title: 'ดูแบบ 360°',
      link: !!currentHotel ? (currentHotel['360_link'] || 'https://www.onceinmemory.com') : 'https://www.onceinmemory.com',
      isSection: false
    },
    {
      id: 2,
      title: 'แกลลอรี่',
      link: '#gallery',
      isSection: true
    },
    {
      id: 3,
      title: 'รีวิว',
      link: '#review',
      isSection: true
    },
    {
      id: 4,
      title: 'โลเคชั่น',
      link: '#map',
      isSection: true
    },
  ]

  //openMobileMenu
  const openMobileMenu = (menu) => {
    if (navTitle === menu) {
      setNavTitle("");
    } else {
      setNavTitle(menu);
    }
  };
  return (
    <nav className="mean-nav">
      <ul>
        {menu_data.map((menu, i) => (
          <React.Fragment key={i}>

            {menu.hasDropdown && (
              <li className="has-dropdown">
                {menu.isSection ? <a href={menu.link}>{menu.title}</a> : <Link to={menu.link}>{menu.title}</Link>}
                <ul
                  className="submenu"
                  style={{
                    display: navTitle === menu.title ? "block" : "none",
                  }}
                >
                  {menu.submenus.map((sub, i) => (
                    <li key={i}>
                      {sub.isSection ? <a href={sub.link}>{sub.title}</a> : <Link to={sub.link}>{sub.title}</Link>}
                    </li>
                  ))}
                </ul>
                <a
                  className={`mean-expand ${navTitle === menu.title ? "mean-clicked" : ""
                    }`}
                  to="#"
                  onClick={() => openMobileMenu(menu.title)}
                  style={{ fontSize: "18px" }}
                >
                  <i className="fal fa-plus"></i>
                </a>
              </li>
            )}
            {!menu.hasDropdown && <li>
              {menu.isSection ? <a href={menu.link}>{menu.title}</a> : <Link to={menu.link}>{menu.title}</Link>}
            </li>}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default MobileMenus;
