import React, { useState, useRef, useEffect, useContext } from "react";
import 'moment/locale/th'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const VideoViewerModal = ({ show, onHide = () => { }, uri = null, name = '' }) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal size="lg" show={show} onHide={() => onHide(true)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div>
            {!!uri && <iframe height="500px" width="100%" allowfullscreen="true"
              src={uri}> </iframe>}
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoViewerModal;
