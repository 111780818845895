import _ from "lodash";

export const optionValue = (_id, opts) => {
    if (_id === undefined || _id === null || _id === '') {
        return null
    }

    return _.find(opts, ['value', _id])
}

export function numberFormat(num, hideDecimalWhenInteger = false, zeroText = '0', decimal_length = 2) {
    var tester = parseFloat(num);

    if (num === 0 || num === null || num === undefined || num === '') {
        if (!hideDecimalWhenInteger && zeroText === '0') {
            return '0.00'
        }

        return zeroText;
    }

    if (tester > Math.floor(tester)) {
        num = tester;
        num = num.toFixed(decimal_length);
    } else if (hideDecimalWhenInteger) {
        if (num != '' && num != null) {
            num = Math.floor(num);
        }
    } else if (tester == Math.floor(tester) && !hideDecimalWhenInteger) {
        num = parseFloat(num).toFixed(decimal_length);
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function inputKeyPressOnlyNumber(event) {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
}


export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
