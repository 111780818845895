/* eslint-disable prefer-object-spread,object-shorthand,no-nested-ternary */
import { toast } from 'react-toastify';
import React, { Component } from 'react';
import axios, { CancelToken } from 'axios';
import { makeid } from './functions'

export const WEB_BASE_URL = 'https://onceinmemory.360diamondoncrown.com';
export const SERVICE_URL = 'https://onceinmemory-api.360diamondoncrown.com/api/';
// export const WEB_BASE_URL = 'http://localhost:3006';
// export const SERVICE_URL = 'http://localhost:3001/api/';

const requester = axios.create({
    baseURL: SERVICE_URL
});

export const showCommonError = (error) => {
    if (error.response && error.response.data) {
        let msg = "";

        switch (error.response.status) {
            // case 401:
            //     msg = 'รหัสผ่าน/การเข้าสู่ระบบผิดพลาด';
            //     break;
            case 403:
                msg = 'ขออภัย คุณไม่ได้รับสิทธิการเข้าถึงข้อมูลนี้';
                break;
            case 404:
                msg = 'ไม่พบข้อมูล';
                break;
            case 500:
                msg = 'เกิดข้อผิดพลาดเซิฟเวอร์ กรุณาติดต่อเจ้าหน้าที่';
                break;
            default:
                if (error.response.data.error) {
                    msg = error.response.data.error.message;
                } else {
                    msg = error.response.data.message;
                }
                break;
        }

        const msgHtml = () => <div
            dangerouslySetInnerHTML={{ __html: msg.replaceAll('\r\n', '<br/>') }}
        />
        toast.warn(msgHtml);
    }
};

const getToken = () => {
    return `${makeid(26)}f${makeid(17)}A${makeid(11)}k${makeid(8)}e${makeid(23)}`
}

const getHeader = (token = null) => {
    token = token || getToken();

    if (!token) {
        return {};
    }

    return { 'x-access-token': token };
}

const api = {
    request: (url, callback = () => { }, params = {}, method = 'GET', options = {}) => {
        options = { ...{ showError: method !== 'GET', showSuccess: false }, ...options }

        const cancelSource = CancelToken.source();
        const cancelToken = cancelSource.token;
        const { showError, baseURL, forceCallbackError } = options;

        requester(Object.assign({
            method,
            url,
            baseURL,
            headers: getHeader(options.token),
            cancelToken
        }, method === 'GET' ? { params: params } : { data: params })).then((response) => {
            callback(response.data, true);
            if (options.showSuccess) {
                toast.success(response.data.message ? response.data.message : (method === 'DELETE' ? 'Data removed !' : 'Data saved !'))
            }
        }).catch((error) => {
            if (forceCallbackError) {
                callback(error, false)
                return
            }

            if (!error.response) {
                return;
            }

            // if (error.response.status === 401 && error.response.data?.message === "Unauthorized!") {
            //     window.location.replace(`${WEB_BASE_URL}${DEFAULT_PATHS.LOGIN}`);
            //     callback({ items: [] }, false);
            //     return;
            // }

            if (showError) {
                showCommonError(error);
            }

            callback(null, false)
        });

        return cancelSource;
    },
    showCommonError: showCommonError,
    getToken: getToken
};

export default api;
