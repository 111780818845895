import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import { Lightbox } from "react-modal-image";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function BookingFromCustomer() {
  const navigate = useNavigate();
  const { book_type, room_id, t_price, s_date, e_date } = useParams();

  const defaultPrice = Number(t_price)
  const [room, setRoom] = useState({});
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(null);

  const [addons, setAddons] = useState([]);
  const [addonOptions, setAddonsOptions] = useState([]);

  var a = moment(s_date, 'YYYYMMDD');
  var b = moment(e_date, 'YYYYMMDD');

  const dayQty = b.diff(a, 'days')

  let bookingItems = []
  for (let i = 0; i < dayQty; i++) {
    const d = moment(s_date, 'YYYYMMDD').add(i, 'days').toDate()
    bookingItems.push({
      date: d,
      price: defaultPrice / dayQty,
    })
  }

  const [form, setForm] = useState({
    start_date: moment(a).format('YYYY-MM-DD'),
    end_date: moment(b).subtract(1, 'days').format('YYYY-MM-DD'),
    payment_type: 'full',
    payment_state: 'waiting',
    state: 'payment',
    booking_date: new Date(),
    hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b',
    room_id: book_type === 'car' ? room_id : null,
    // unit_price: room?.price || 0,
    // unit_vat_price: room?.vat_price || 0,
    // unit_total_price: room?.total_price || 0,
    total_price: defaultPrice || 0,
    booking_note: '',
    slip_file: null
  });

  let discountPrice = 0

  if (discount && discount?.coupon) {
    const c = discount?.coupon

    if (c.discount_type === 'static') {
      discountPrice = Number(c.discount_price) || 0
    } else {
      discountPrice = (Number(form?.total_price || 0) || 0) * ((Number(c.discount_price) || 0) / 100)
    }

    if (discountPrice > Number(form.total_price)) {
      discountPrice = Number(form.total_price)
    }
  }

  const sumAddons = _.sumBy(addons || [], (x) => { return Number(x.total_price || 0) }) || 0;
  const payAmount = Number(form.total_price) - discountPrice + sumAddons

  const fetchHotelAddonsItem = (hId) => {
    api.request(`hotel-addons/all`, (data) => {
      setAddonsOptions(data.map((item) => {
        return {
          label: item.name_th,
          value: item.id,
          _price: item.default_price || 0
        }
      }))
    }, { hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b', enabled: true, is_non_inventory: true })
  }

  const validateAddons = () => {
    if (!addons.length) {
      return true
    }

    let l = (addons.filter((x) => !!x.addons_id)) || []

    return l.length === addons.length
  }

  const fetchRoom = () => {
    api.request(`rooms/${room_id}`, (data) => {
      setRoom(data || {})
    }, {})
  }

  const checkRoomBeforeSubmit = () => {
    api.request('bookings/all-check-room', (data) => {
      if (data && data.length) {
        alert('ห้องพักไม่ว่างในวันที่เลือก! กรุณาติดต่อผู้ดูแลระบบ')
        window.location.replace("https://www.facebook.com/newzealandcamping");
        return
      } else {
        fetchRoom()
      }
    }, {
      room_id: room_id,
      start_date: moment(a).format('YYYY-MM-DD'),
      end_date: moment(b).subtract(1, 'days').format('YYYY-MM-DD')
    })
  }

  useEffect(() => {
    if (s_date && e_date && book_type === 'car' && room_id !== 'abc') {
      checkRoomBeforeSubmit()
      // fetchRoom()
    }
  }, [s_date, e_date, book_type, room_id])

  useEffect(() => {
    if (book_type === 'walkin') {
      const g_qty = form.guest_qty || 0
      const c_qty = form.guest_child_qty || 0

      const p = ((g_qty * 200) + (c_qty * 100) * dayQty)
      setForm({ ...form, total_price: p })
    }
  }, [form.guest_qty, form.guest_child_qty])

  useEffect(() => {
    fetchHotelAddonsItem()
  }, [])

  return (
    <>
      {/* <Menu header_style_2={true} /> */}
      <div className="text-left">
        <div className="p-3">
          {book_type === 'walkin' && <h5>รายการเข้าพัก : เช่าพื้นที่กางเต็นท์/Car Camp/Walk - In</h5>}
          {book_type === 'car' && <h5>รายการเข้าพัก : {room?.name_th} ({room?.suggestion_guest_info_th})</h5>}
          <div className="d-flex">
            <strong>วันที่เข้าพัก : </strong> <span className="ms-2">{moment(a).format("DD/MM/YYYY")} - {moment(b).format("DD/MM/YYYY")} ({dayQty} คืน)</span>
          </div>
          <div> <strong>ราคารวม : </strong><span className="ms-2 text-success">{numberFormat(form.total_price)} บาท</span></div>
          {book_type === 'walkin' && <div>
            <div>
              <i className="fal fa-angle-right"></i> <span>ผู้ใหญ่ ท่านละ 200 บาท</span>
            </div>
            <div>
              <i className="fal fa-angle-right"></i> <span>เด็ก (อายุ 6 - 12 ปี) ท่านละ 100 บาท</span>
            </div>
            <div>
              <i className="fal fa-angle-right"></i> <span>เด็ก (ต่ำกว่า 6 ปี) ไม่คิดค่าบริการ</span>
            </div>
          </div>}
          <div className="booking-form-input my-3">
            <div className="mt-2">
              <strong className="text-warning">ส่วนลด/คูปอง (ถ้ามี)</strong>

              {!discount && <Row className="mb-1">
                <Col xs={8}>
                  <input
                    type="text"
                    name="coupon_code"
                    placeholder="ระบุโค้ดส่วนลด"
                    value={couponCode || ""}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                </Col>
                <Col xs={4}>
                  <Button disabled={!couponCode?.length} className="btn btn-sm btn-success" onClick={() => {
                    setDiscount(null)

                    api.request(`coupons/items/${couponCode}`, (data, suc) => {
                      if (!suc) {
                        return
                      }

                      if (data.error) {
                        toast.warn(data.message)
                        return
                      }
                      setDiscount(data.coupon)
                    }, {})
                  }}>
                    ใช้คูปอง
                  </Button>
                </Col>
              </Row>}
              {!!discount && <Row className="mb-1">
                <Col xs={8}>
                  <strong className="text-success"> ส่วนลดที่ได้รับ : {numberFormat(discountPrice)} บาท</strong>
                </Col>
                <Col xs={4}>
                  <Button className="btn btn-sm btn-danger" onClick={() => {
                    setCouponCode('')
                    setDiscount(null)
                  }}>
                    ยกเลิกคูปอง
                  </Button>
                </Col>
              </Row>}
            </div>
            <p className="text-black mt-3 mb-1">จำนวนผู้เข้าพัก</p>
            <Row className="mb-1">
              <Col xs={6}>
                <label>ผู้ใหญ่</label>
                <input
                  type="number"
                  name="guest_qty"
                  placeholder="ผู้ใหญ่"
                  min={1}
                  onKeyPress={inputKeyPressOnlyNumber} value={form.guest_qty || ""}
                  onChange={(e) => setForm({ ...form, guest_qty: e.target.value })}
                  required
                />
              </Col>
              <Col xs={6}>
                <label>เด็ก 6 - 12 ปี</label>
                <input
                  type="number"
                  name="guest_child_qty"
                  placeholder="เด็ก 6 - 12 ปี"
                  onKeyPress={inputKeyPressOnlyNumber} value={form.guest_child_qty || ""}
                  onChange={(e) => setForm({ ...form, guest_child_qty: e.target.value })}
                />
              </Col>
              <Col xs={6}>
                <label>เด็กต่ำกว่า 6 ปี</label>
                <input
                  type="number"
                  name="guest_baby_qty"
                  placeholder="เด็กต่ำกว่า 6 ปี"
                  onKeyPress={inputKeyPressOnlyNumber} value={form.guest_baby_qty || ""}
                  onChange={(e) => setForm({ ...form, guest_baby_qty: e.target.value })}
                />
              </Col>
              {book_type === 'walkin' && <Col xs={6}>
                นำมากี่เต็นท์/คัน
                <input
                  type="number"
                  name="tent_qty"
                  placeholder="เต็นท์/คัน"
                  onKeyPress={inputKeyPressOnlyNumber} value={form.tent_qty || ""}
                  onChange={(e) => setForm({ ...form, tent_qty: e.target.value })}
                />
              </Col>}
            </Row>
            <input
              className="mt-2"
              type="text"
              name="booking_contact_name"
              placeholder="ระบุชื่อผู้ติดต่อ"
              value={form.contact_name || ""}
              onChange={(e) => setForm({ ...form, contact_name: e.target.value })}
              required
            />
            <input
              className="mt-2"
              type="text"
              name="booking_tel_1"
              placeholder="เบอร์ติดต่อ (กรุณาระบุเฉพาะตัวเลข)"
              onKeyPress={inputKeyPressOnlyNumber} value={form.contact_tel_1 || ""}
              onChange={(e) => setForm({ ...form, contact_tel_1: e.target.value })}
              required
            />
            <input
              className="mt-2"
              type="text"
              name="contact_line_id"
              placeholder="ไลน์ไอดี/LINE ID (ถ้ามี)"
              value={form.contact_line_id || ""}
              onChange={(e) => setForm({ ...form, contact_line_id: e.target.value })}
            />
            <input
              className="mt-2"
              type="text"
              name="contact_email"
              placeholder="อีเมล (ถ้ามี)"
              value={form.contact_email || ""}
              onChange={(e) => setForm({ ...form, contact_email: e.target.value })}
            />
            <input
              className="mt-2"
              type="text"
              name="customer_car_model"
              placeholder="ยี่ห้อรถ"
              value={form.customer_car_model || ""}
              onChange={(e) => setForm({ ...form, customer_car_model: e.target.value })}
            />
            <input
              className="mt-2"
              type="text"
              name="customer_car_license_plate"
              placeholder="ทะเบียนรถ"
              value={form.customer_car_license_plate || ""}
              onChange={(e) => setForm({ ...form, customer_car_license_plate: e.target.value })}
            />
            <textarea
              className="mt-2"
              type="text"
              name="booking_note"
              placeholder="ความต้องการเพิ่มเติม"
              value={form.booking_note || ""}
              onChange={(e) => setForm({ ...form, booking_note: e.target.value })}
            />
          </div>
          {addonOptions && addonOptions.length > 0 && <div className="mt-2">
            <strong className="text-warning">รายการอุปกรณ์เสริม(ถ้ามี)</strong>
            <div className="mb-3">
              {addons.map((ad, i) => {
                return (
                  <div className="mt-3" key={`addons-list-${i}`}>
                    <div className="d-flex mb-3 ">
                      <div className="top-label" style={{ flex: 1 }}>
                        <Form.Label htmlFor={`addons_name_${i}`}>อุปกรณ์เสริม{i + 1}</Form.Label>
                        <Select
                          options={addonOptions}
                          onChange={(option) => {
                            let ads = [...addons]
                            ad.addons_id = option.value
                            ad.unit_price = option._price
                            ad.total_price = ((Number(option._price) * Number(ad.qty)) || 0)
                            ads[i] = ad
                            setAddons(ads)
                          }}
                          className={'flex-fill'}
                          placeholder="เลือกอุปกรณ์เสริม"
                          value={optionValue(ad.addons_id, addonOptions)}
                          classNamePrefix="react-select"
                          id={`addons_name_${i}`}
                        />
                      </div>
                      <div className="top-label ms-2" style={{ width: 70 }}>
                        <Form.Label htmlFor={`addons_qty_${i}`}>จำนวน</Form.Label>

                        <Form.Control id={`addons_qty_${i}`} type="number" min="1" onKeyPress={inputKeyPressOnlyNumber} value={ad.qty || ""} onChange={(e) => {
                          let ads = [...addons]
                          ad.qty = e.target.value
                          ad.total_price = Number(e.target.value) * Number(ad.unit_price)
                          ads[i] = ad
                          setAddons(ads)
                        }} />
                      </div>
                      <div className="top-label ms-2" style={{ width: 100 }}>
                        <Form.Label htmlFor={`addons_price_${i}`}>ราคา</Form.Label>
                        <Form.Control id={`addons_price_${i}`} type="text" value={numberFormat(ad.total_price) || ""} disabled />
                      </div>
                      <Button variant="outline-danger" disabled={submitting} className="btn-icon btn-icon-only ms-2" onClick={() => {
                        let ads = [...addons]
                        ads.splice(i, 1)
                        setAddons(ads)
                      }}>
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  </div>
                )
              })}
              {addons && addons.length > 0 && <p className="text-primary">รวมค่าบริการอุปกรณ์เสริม {numberFormat(sumAddons || 0)}</p>}
              <Button variant="light" disabled={submitting} className="btn-icon btn-sm" onClick={() => {
                let ads = [...addons]
                ads.push({ name: '', qty: 1, total_price: 0, unit_price: 0 })
                setAddons(ads)
              }}>
                <i className="fa fa-plus me-2" />
                เพิ่มอุปกรณ์เสริม
              </Button>
            </div>
          </div>}
          <div>
            <p className="text-black mt-3 mb-1">ช่องทางการชำระเงิน</p>
            <div>
              <h5><strong>จำนวนเงินที่ต้องชำระ : </strong><span className="ms-2 text-success">{numberFormat(payAmount)} บาท</span></h5>
            </div>
            <div>
              <strong>ชำระโดย : </strong><span className="ms-2 text-black">โอนผ่านบัญชีธนาคาร "กสิกรไทย"</span>
            </div>
            <div>
              <strong>เลขที่บัญชี : </strong><span className="ms-2 text-black">159-3-14157-4</span>
            </div>
            <div>
              <strong>ชื่อบัญชี : </strong><span className="ms-2 text-black">น.ส. จิราพรรณ สุดเตาะ</span>
            </div>
            <div className="text-center py-2">
              <img style={{ width: 250 }} src={"/assets/img/booking/bank_qr.jpg"} alt="img" />
            </div>
            <div className="d-flex mb-2 align-items-end mt-3">
              <div>
                <label htmlFor={`form_file_slip`} className="form-label">แนบหลักฐานการชำระเงิน</label>
                <input accept="image/*" className="form-control" id={`form_file_slip`} type="file" onChange={(e) => {
                  if (e?.target?.files && e?.target?.files[0]) {
                    setForm({ ...form, slip_file: e.target.files[0] })
                  }
                }} />
              </div>
              <div className="ms-2" style={{ width: 40 }}>
                {!!form.slip_file &&
                  <img src={URL.createObjectURL(form.slip_file)} className="rounded-sm" style={{ width: 40, height: 40 }} onClick={() => {
                    setIsLightboxOpen(true)
                  }} />}
              </div>
            </div>
            <Button disabled={submitting} className="btn btn-sm btn-warning" onClick={() => {
              if (dayQty < 1) {
                toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
                return
              }

              if (!form.contact_name) {
                toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
                return
              }

              if (!form.contact_tel_1) {
                toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
                return
              }

              if (!form.total_price || form.total_price < 1) {
                toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
                return
              }

              if (payAmount > 0 && !form.slip_file) {
                toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
                return
              }

              if (!form.guest_qty && form.guest_qty < 1) {
                toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
                return
              }

              setShowConfirmModal(true)
            }}>ยืนยันการจอง</Button>
          </div>
        </div>
        {isLightboxOpen && !!form.slip_file && (
          <Lightbox
            medium={URL.createObjectURL(form.slip_file)}
            large={URL.createObjectURL(form.slip_file)}
            alt={'Payment Slip'}
            onClose={() => {
              setIsLightboxOpen(false)
            }}
            hideDownload
            hideZoom
          />
        )}
      </div>
      <Modal className="fade modal-close-out" show={showConfirmModal}>
        <Modal.Header>
          <Modal.Title>{'ยืนยันการจอง'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <p>
            <span>{'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'} </span>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"secondary"} onClick={() => setShowConfirmModal(false)}>ปิด</Button>
          <Button disabled={submitting} variant="warning" onClick={() => {
            if (dayQty < 1) {
              toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
              return
            }

            if (!form.contact_name) {
              toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
              return
            }

            if (!form.contact_tel_1) {
              toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
              return
            }

            if ((!form.total_price || form.total_price < 1)) {
              toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
              return
            }

            if (payAmount > 0 && !form.slip_file) {
              toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
              return
            }

            if (!form.guest_qty && form.guest_qty < 1) {
              toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
              return
            }

            if (!validateAddons()) {
              toast.warn('กรุณาเลือกอุปกรณ์เสริมให้ครบถ้วน หรือลบรายการที่ไม่ต้องการออก')
              return
            }

            setSubmitting(true)

            const rawForm = {
              ...form,
              day_qty: dayQty,
              room_name: room?.name_th || '',
              guest_child_qty: form.guest_child_qty || 0,
              guest_baby_qty: form.guest_baby_qty || 0,
              tent_qty: form.tent_qty || 0,
              is_walkin: book_type === 'walkin' ? 1 : 0,
              discount_code: couponCode,
              total_discount: discountPrice || 0
            }

            const formData = new FormData();
            for (var key in rawForm) {
              if (key !== 'slip_file') {
                formData.append(key, rawForm[key]);
              }
            }

            if (payAmount > 0) {
              formData.append("file", rawForm.slip_file);
            }

            if (book_type !== 'walkin') {
              bookingItems.map((b, i) => {
                formData.append(`booking_items[${i}]`, JSON.stringify(b));
              })
            }

            if (addons && addons.length) {
              addons.map((addonObj, i) => {
                formData.append(`booking_addons[${i}]`, JSON.stringify(addonObj));
              })
            }

            api.request(`bookings/bookingformweb`, (res, success) => {
              setSubmitting(false)
              setShowConfirmModal(false)
              if (!success) {
                return
              } else {
                toast.success('ทำการจองเรียบร้อยแล้ว')
                navigate(`/once/status/${res.id}/${res.booking_no}`)
              }
            }, formData, 'POST')
          }}>
            ยืนยันการจอง
          </Button>
        </Modal.Footer>
      </Modal>
      <OnceFooter />
    </>
  );
}
