import React from 'react';

const OnceFooter = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-30">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-1 pb-30">
                      <div className="footer-logo mb-30" style={{ width: '220px' }}>
                        <a href="#"><img src="/assets/img/logo/logo.png" alt="" /></a>
                      </div>
                      <h3 className="tp-footer__widget-title text-white">ครั้งหนึ่ง...ในความทรงจำ</h3>
                      <span>"ประสบการณ์ เฉพาะในแบบของคุณ"</span>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Our Location</h3>
                      <ul>
                        <li><a href="#">ลพบุรี</a></li>
                        <li><a href="#">กรุงเทพมหานคร</a></li>
                      </ul>
                    </div>
                  </div> */}

                  {/* <div className="col-lg-4 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-3 pb-30">
                      <h3 className="tp-footer__widget-title text-white">โทร/Line ID</h3>
                      <ul> */}
                      {/* <li><a href="#">09-5935-4624</a></li> */}
                        {/* <li><a href="#">บริษัท เพชรยอดมงกุฏ จำกัด</a></li>
                        {/* <li><a href="#">บริษัท เพชรยอดมงกุฏ จำกัด</a></li>
                        <li><a href="#">ตำบลเขาพระงาม อำเภอเมือง จังหวัดลพบุรี</a></li> */}
                        {/* <li><a href="tel:+66911205531">09-9999-9999</a></li>
                        <li><a href="mailto:support@example.com">support@onceinmemory.com</a></li> */}
                        {/* <li><span> Office Hours: 8AM - 6PM</span></li>
                        <li><span> Open Everyday</span></li> */}
                      {/* </ul>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2 footer-col-2-4  pb-30">
                      <h3 className="tp-footer__widget-title text-white">Newslatter</h3>
                      <div className="footer-form-3 mb-30">
                        <form>
                          <input type="email" placeholder="Enter your mail" />
                          <button type="submit">Subscribe <i
                            className="fal fa-paper-plane"></i></button>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-20 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="tp-copyrigh-text mb-20">
                <div>"ระบบจองที่พัก สมัยใหม่"</div>
                <span>© {new Date().getFullYear()} ONCE IN MEMORY</span>
              </div>
            </div>
            {/* <div className="col-md-4 col-12">
              <div className="tp-footer-social-icon mb-30 text-md-end">
                <ul>
                  <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                  <li><a href="#"><i className="fab fa-tiktok"></i></a></li>
                  <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default OnceFooter;
