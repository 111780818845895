import React, { useState, useEffect, useRef } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api, { WEB_BASE_URL } from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber } from './../../utils/functions';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import { end } from '@popperjs/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import "react-datepicker/dist/react-datepicker.css";

const renderEventContent = (eventInfo) => {
  return (
    <>
      <b>ไม่ว่าง</b>
    </>
  )
}

export default function BookingGenerateUrl() {
  const [bookingType, setBookingType] = useState('walkin'); // walkin || car
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState({});
  const [price, setPrice] = useState(0);
  const baseUrl = `${WEB_BASE_URL}/once/self-booking/`
  const [urlText, setUrlText] = useState('');

  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [bookings, setBookings] = useState([])
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [calendarEndDate, setCalendarEndDate] = useState(null);
  const calendarRef = useRef(null);

  const [excludeDates, setExcludeDates] = useState([])

  const onPrevButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentMonth(calendarApi.currentData?.currentDate || new Date())
  };

  const onNextButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentMonth(calendarApi.currentData?.currentDate || new Date())
  };

  const getToday = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today();
      setCurrentMonth(new Date())
    }
  };

  const handleDates = (rangeInfo) => {
    setCalendarStartDate(rangeInfo?.start)
    setCalendarEndDate(rangeInfo?.end)
  };

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const a = moment(new Date(moment(endDate).format('YYYY-MM-DD')));
  const b = moment(new Date(moment(startDate).format('YYYY-MM-DD')));
  const dayQty = a.diff(b, 'days') | 0

  // let newEvent = []
  // if (startDate && endDate && a && b) {
  //   newEvent = [{
  //     start: moment(startDate).format('YYYY-MM-DD'),
  //     end: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
  //     color: '#57BD37',
  //   }]
  // }

  const fetchRooms = () => {
    api.request('rooms/all', (data) => {
      if (data && data.length) {
        setRooms(data)
      } else {
        setRooms([])
      }
    }, {
      hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b',
      room_type_id: '248777bc-2bc9-4d85-8c7c-4ef3fa94014f',
      enabled: true
    })
  }

  const fetchฺBooking = (room) => {
    api.request('bookings/all-check-room', (data) => {
      if (data && data.length) {

        let ex = []

        data.map((item) => {
          const num = Number(item.day_qty)
          if (num > 1) {
            for (let i = 0; i < num; i++) {
              if (i === 0) {
                ex.push(new Date(item.start_date))
              } else {
                const nDate = new Date(item.start_date)
                ex.push(nDate.setDate(nDate.getDate() + i))
              }
            }
          } else {
            ex.push(new Date(item.start_date))
          }
        })

        setExcludeDates(ex)

        // setBookings(data.map((item) => {

        //   let d = new Date(item.end_date)
        //   d.setDate(d.getDate() + 1);
        //   return {
        //     title: 'ไม่ว่าง',
        //     start: item.start_date,
        //     end: moment(d).format('YYYY-MM-DD'),
        //     end_raw: item.end_date,
        //     color: 'red',
        //   }
        // }))
      } else {
        setExcludeDates([])
        // setBookings([])
      }
    }, {
      hotel_id: room.hotel_id,
      room_type_id: room.room_type_id,
      room_id: room.id,
      start_date: calendarStartDate,
      end_date: calendarEndDate
    })
  }

  const fetchBookingData = (rId) => {
    api.request('bookings/all-check-room', (data) => {
      if (data && data.length) {
        setStartDate(null)
        setEndDate(null)
        setPrice(0)
        alert('ห้องพักไม่ว่างในวันที่เลือก! กรุณาเลือกวันอื่นๆ')
        return
      } else {
        setPrice((room?.total_price || 0) * dayQty)
      }
    }, {
      room_id: rId,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD')
    })
  }

  useEffect(() => {
    if (room?.id && bookingType === 'car') {
      fetchฺBooking(room)
    }
  }, [room?.id])

  useEffect(() => {
    if (!!startDate && !!endDate && !!room?.id && bookingType === 'car') {
      fetchBookingData(room.id)
    }
  }, [startDate, endDate, room])

  useEffect(() => {
    fetchRooms()
  }, [])

  useEffect(() => {
    setUrlText('')
  }, [bookingType, room, startDate, endDate, price])

  return (
    <>
      {/* <Menu header_style_2={true} /> */}
      <div className="text-center">
        <div className="px-5 py-3">
          <div className="d-flex">
            <label className="form-check">
              <input type="radio" className="form-check-input" checked={bookingType === 'walkin'} onChange={(e) => setBookingType('walkin')} name="bookingTypeInput" />
              <span>กางเต็นท์</span>
            </label>
            <label className="form-check ms-3">
              <input type="radio" className="form-check-input" checked={bookingType === 'car'} onChange={(e) => setBookingType('car')} name="bookingTypeInput" />
              <span>เช่ารถบ้าน</span>
            </label>
          </div>
          {bookingType === 'car' && rooms.length > 0 && <div className="text-start mt-2">
            <p className="text-black">เลือกรถบ้าน</p>
            <div>
              {rooms.map((r, i) => {
                return (
                  <label className="form-check ms-2" key={`select-room-${r.id}`}>
                    <input type="radio" className="form-check-input" checked={room?.id === r.id} onChange={(e) => {
                      setRoom(r)
                    }} name="carId" />
                    <span>{r.name_th} : {r.suggestion_guest_info_th}</span>
                  </label>
                )
              })}
            </div>
          </div>}
          <div className="booking-form-input">
            <div className="mt-2">

              <DatePicker
                className="form-control search-booking-datepicker"
                minDate={new Date()}
                swapRange
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                excludeDates={excludeDates}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                dateFormat="dd/MM/yyyy"
              />

              {/* <div className="d-flex mb-3 align-items-center">
                <div className="text-black">{moment(currentMonth).format('MMMM YYYY')}</div>
                <div className="arrow-btn-group d-flex">
                  <button className="btn-common-light" onClick={getToday}>
                    <span>เดือนนี้</span>
                  </button>
                  <button
                    onClick={onPrevButtonClick}
                    type="button"
                    className="arrow-btn  ms-2"
                  >
                    <i className="fal fa-angle-left"></i>
                  </button>
                  <button
                    onClick={onNextButtonClick}
                    type="button"
                    className="arrow-btn ms-2"
                  >
                    <i className="fal fa-angle-right"></i>
                  </button>
                </div>
              </div> */}
              {/* <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
                headerToolbar={false}
                initialView='dayGridMonth'
                themeSystem="bootstrap"
                events={[...(!!room?.id && bookingType === 'car' ? bookings : []), ...newEvent]}
                datesSet={handleDates}
                longPressDelay={0}
                selectable
                select={(d) => {

                  if (!d?.start || !d?.end) {
                    return
                  }

                  setStartDate(d.start)
                  setEndDate(moment(d.end).subtract(1, 'days'))
                }}
              /> */}
            </div>
            <div className="pe-5 ps-2 mt-2">
              <div className="text-start">
                <div>จำนวนวันที่เข้าพัก</div>
                {startDate && endDate && <strong className="text-black">{moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')} ({dayQty}) คืน</strong>}
                {(!startDate || !endDate) && <div className="text-warning">กรุณาเลือกวันเข้าพัก</div>}
              </div>
            </div>
            <div className="pe-5 ps-2">
              <div className="text-start">
                <div>ราคา</div>
                <input
                  type="number"
                  name="price"
                  placeholder="ระบุราคา"
                  onKeyPress={inputKeyPressOnlyNumber} value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>
            </div>
            <Button className="btn btn-sm btn-success mt-4" onClick={() => {
              if (dayQty < 1) {
                alert('กรุณาเลือกวันให้ถูกต้อง !')
                return
              }

              if (bookingType === 'car' && !room?.id) {
                alert('อย่าลืมเลือกรถก่อนค่ะ !')
                return
              }
              const url = `${baseUrl}${bookingType}/${bookingType === 'car' ? room.id : 'abc'}/${price || 0}/${moment(startDate).format('YYYYMMDD')}/${moment(endDate).format('YYYYMMDD')}`
              setUrlText(url)
            }}>Generate Link</Button>
            {!!urlText && urlText.length > 0 && <div className="text-start mt-3 border p-2">
              <div> <small className="text-start text-black">{urlText}</small></div> <Button className="btn btn-sm btn-warning" onClick={() => {
                navigator.clipboard.writeText(urlText)
              }}>Copy Link</Button>
            </div>}
          </div>
        </div>
      </div>
      <OnceFooter />
    </>
  );
}
