import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../context/ContextProvider";

moment.locale('th')

const PolicyConfirmModal = ({ show, onHide = () => { }, hotel_id = {}, onAcceptTerm = () => { } }) => {
  const { currentHotel } = useContext(AppContext);

  useEffect(() => {
  }, [])

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>กรุณาอ่านเงื่อนไข และตรวจสอบข้อมูลให้ถูกต้อง</Modal.Title>
        </Modal.Header>
        <Modal.Body className="booking-policy">
          {!!currentHotel.terms_of_use_th && <div>
            <h4 className="mb-3">{currentHotel?.terms_of_use_title_th}</h4>
            <div dangerouslySetInnerHTML={{ __html: currentHotel.terms_of_use_th }} />
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div className="tp-purchase-btn py-3 px-5" onClick={() => onAcceptTerm()}>
              ยืนยัน {" "}
              <span>
                <svg
                  width="22"
                  height="8"
                  viewBox="0 0 22 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <svg
                  width="22"
                  height="8"
                  viewBox="0 0 22 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          {/* <Button variant="secondary">ถัดไป</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PolicyConfirmModal;
