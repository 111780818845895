import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button, Row, Col, Accordion } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { AppContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import { numberFormat } from './../../utils/functions'
import _ from "lodash";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

moment.locale('th')

const RoomListModal = ({ show, onHide = () => { } }) => {
  const navigate = useNavigate();

  const { handleSetRoomSelected, currentHotel } = useContext(AppContext);
  const [roomTypes, setRoomTypes] = useState([])

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [roomIdsInbookings, setRoomIdsInBookings] = useState([]);

  const fetchRoomTypes = () => {
    if (!currentHotel?.id) return

    api.request(`room-types/all-rooms`, (data) => {
      if (data && data.length) {
        setRoomTypes(_.filter(data, (t) => { return t.rooms && t.rooms.length }) || [])
      } else {
        setRoomTypes([])
      }
    }, { hotel_id: currentHotel.id })
  }

  const fetchฺBookingFromDate = () => {
    if (!startDate || !endDate) {
      setRoomIdsInBookings([])
      return
    }

    api.request('bookings/all-check-room', (data) => {
      console.log(data)
      if (data && data.length) {
        setRoomIdsInBookings(data.map((item) => { return item.room_id }))
      } else {
        setRoomIdsInBookings([])
      }
    }, {
      hotel_id: currentHotel.id,
      start_date: startDate,
      end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'),
      ignore_walkin: true
    })
  }

  useEffect(() => {
    if (!show) {
      setRoomTypes([])
      setRoomIdsInBookings([])
      setStartDate(null)
      setEndDate(null)
    }
    fetchRoomTypes()
  }, [show])

  useEffect(() => {
    fetchฺBookingFromDate()
  }, [startDate, endDate])

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>เลือกห้องที่ต้องการดูข้อมูล</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="top-label">
              <span className="me-2">เลือกวันที่ต้องการเข้าพัก</span>

              <DatePicker
                className="form-control search-booking-datepicker"
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>
          </div>
          <Accordion defaultActiveKey={"acc-0"} className="mb-4">
            {roomTypes.map((t, i) => {
              return (
                <Accordion.Item key={`select-room-type-${i}`} eventKey={`acc-${i}`}>
                  <Accordion.Header>
                    <div className="d-flex align-items-center">
                      <i className="fal fa-list" style={{ fontSize: 20 }}></i>
                      <div className="ms-3">
                        <h5 className="mt-2">{t.name_th}</h5>
                        {!!t.description_th && <p className="mb-0">{t.description_th}</p>}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {t.rooms.map((r, ri) => {
                      return (
                        <div key={`room-sub-list-${ri}`}>
                          {(ri > 0) && <hr style={{ color: '#D5DADE' }} />}
                          <div key={`select-room-${i}-${ri}`}>
                            <div className="d-flex align-items-center" onClick={() => {
                              onHide()
                              navigate(`/once/${r.id}`)
                            }}>
                              {!!r.room_medias && !!r.room_medias.length && <img className="rounded" style={{ width: 80 }} src={r.room_medias[0].file_path} alt="" />}
                              {!r.room_medias || !r.room_medias.length && <div className="text-center p-2 border rounded">
                                <i className="fal fa-images text-muted" style={{ fontSize: 20 }}></i>
                                <div><small className=" text-muted">No Image</small></div>
                              </div>}
                              <div className="ms-3">
                                <h5 className="mt-2">{r.name_th} | {numberFormat(r.total_price, true)} บาท <small className="text-muted">{r.suggestion_guest_info_th}</small></h5>
                                {!!r.short_info_th && <p className="mb-0">{r.short_info_th}</p>}
                              </div>
                            </div>
                            {_.includes(roomIdsInbookings, r.id) && <p className="text-danger"><small>ขออภัย ! ห้องพักนี้ไม่ว่างในวันที่ค้นหา</small></p>}
                          </div>
                        </div>
                      )
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
          {/* <Row>
            <Col xs="12" md="4">
              <div className="text-center" onClick={() => {
                handleSetRoomSelected({ name: 'รถบ้าน A1' })
                onHide()
              }}>
                <img className="rounded" src="/assets/img/dummy/hotels/1.jpg" alt="" />
                <h5 className="mt-2">รถบ้าน | 2,500 บาท</h5>
              </div>
            </Col>
            <Col xs="12" md="4">
              <div className="text-center" onClick={() => {
                handleSetRoomSelected({ name: 'เต๊นท์ K2' })
                onHide()
              }}>
                <img className="rounded" src="/assets/img/dummy/hotels/2.jpg" alt="" />
                <h5 className="mt-2">เต๊นท์ K2 | 800 บาท</h5>
              </div>
            </Col>
            <Col xs="12" md="4">
              <div className="text-center" onClick={() => {
                handleSetRoomSelected({ name: 'เต๊นท์เล็ก' })
                onHide()
              }}>
                <img className="rounded" src="/assets/img/dummy/hotels/4.jpg" alt="" />
                <h5 className="mt-2">เต๊นท์เล็ก | 500 บาท</h5>
              </div>
            </Col>
          </Row> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoomListModal;
