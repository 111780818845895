import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import RoomListModal from './../Room/RoomListModal';
import RoomDetailModal from './../Room/RoomDetailModal';
import BookingModal from './../Booking/BookingModal';
import Slider from "react-slick";
import slider2 from "../../data/SliderHomePageTwo.json";
import _ from "lodash";
import { AppContext } from "../context/ContextProvider";
import { numberFormat } from './../../utils/functions';
import VideoViewerModal from "./../Room/components/VideoViewerModal";
import BookingCalendar from "../Booking/BookingCalendar";

const TopCtaArea = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [showRoomDetail, setShowRoomDetail] = useState(false)
  const [showRoomList, setShowRoomList] = useState(false)
  const [imgForPreview, setImgForPreview] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)
  const [dateForBooking, setDateForBooking] = useState(null)
  const { roomSelected, currentHotel } = useContext(AppContext);
  const [bookings, setBookings] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [room, setRoom] = useState(roomSelected)

  const [show360Modal, setShow360Modal] = useState(false)

  const roomImageSlidersettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const roomImgForSlide = _.map(roomSelected?.room_medias || [], 'file_path')

  return (
    <>
      {/* <!-- cta area start --> */}
      <div id="room_booking" className="tp-cta-area tp-cta-area-page-two pl-110 pr-200">
        <div className="container-fluid">
          <div className="tp-cta-wrapper home-section-1 pb-105">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle tp-subtitle-before mb-25">
                    {currentHotel?.name_th}
                  </span>
                  <h2 className="tp-section-title tp-section-title-large">
                    {roomSelected?.name_th}
                  </h2>
                  <span>{currentHotel?.district?.name_th} {currentHotel?.province?.name_th}</span>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <BookingCalendar room_id={roomSelected?.id} hotel_id={roomSelected?.hotel_id} />
              </div>
              {!!roomSelected?.room_medias?.length && <div className="col-lg-6 text-end">
                {!!roomSelected['360_link'] && <div className="text-center d-flex justify-content-end align-items-center">
                  <div className="me-2">
                    <img className="icon-rotate-x" style={{ width: 50, height: 50 }} src={"/assets/img/360/icon_1.png"} alt="img" />
                  </div>
                  <div className="tp-btn-border" onClick={() => setShow360Modal(true)}>
                    ดูแบบ 360°
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        />
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                </div>}
                <div className="about-page-img  mt-4" onClick={() => setImgForPreview(roomImgForSlide)} >
                  <Slider  {...roomImageSlidersettings}>
                    {roomSelected?.room_medias.map((img, i) => {
                      return (
                        <div key={`s-room-img-${i + 1}`} className="w-img">
                          <img src={img.file_path} alt="img" />
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>}
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-8">
                  {!!currentHotel?.highlights && !!currentHotel?.highlights.length && <div className="rounded border p-3" >
                    <p className="text-black">จุดเด่นที่พัก</p>
                    <div className="">
                      {currentHotel?.highlights.map((h, i) => {
                        return (
                          <div key={`s-room-highlight-${i + 1}`} className="d-inline-block ms-3">
                            <i className="fa fa-star text-warning me-2"></i>
                            <span>{h.name_th}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>}
                  {!!currentHotel?.facilities && !!currentHotel?.facilities.length && <div className="p-3" >
                    <p className="text-black">สิ่งอำนวยความสะดวก</p>
                    <div className="">
                      {currentHotel?.facilities.map((h, i) => {
                        return (
                          <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-3">
                            <i className="fal fa-check text-success me-2"></i>
                            <span>{h.name_th}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>}
                </div>
                <div className="col-lg-4">
                  {!!currentHotel?.hotel_nearby_places && !!currentHotel?.hotel_nearby_places.length && <div className="p-3" >
                    <p className="text-black">สถานที่ใกล้เคียง</p>
                    <div className="">
                      {currentHotel?.hotel_nearby_places.map((h, i) => {
                        const distance = h.distance ? (Number(h.distance) / 1000) : null
                        return (
                          <div key={`s-room-near-${i + 1}`} className="">
                            <i className="fas fa-map-marker-alt text-black me-2"></i>
                            <span>{h.name_th}</span>
                            {!!distance && <small className="ms-2">{'(' + numberFormat(distance, true, '0', 1) + ' กม.)'}</small>}
                          </div>
                        )
                      })}
                    </div>
                  </div>}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
      <RoomDetailModal
        show={showRoomDetail}
        onHide={() => setShowRoomDetail(false)}
        room={roomSelected}
      />
      <RoomListModal
        show={showRoomList}
        onHide={() => setShowRoomList(false)}
      />
      <BookingModal
        show={!!dateForBooking}
        onHide={(_ignore_reload) => {
          setDateForBooking(null)
          if (!_ignore_reload) {
            window.location.reload()
          }
        }}
        date={dateForBooking}
        bookingList={bookings}
      />
      {(!!imgForPreview && !!imgForPreview.length) && (
        <Lightbox
          mainSrc={imgForPreview[photoIndex]}
          nextSrc={imgForPreview[(photoIndex + 1) % imgForPreview.length]}
          prevSrc={imgForPreview[(photoIndex + imgForPreview.length - 1) % imgForPreview.length]}
          onCloseRequest={() => {
            setImgForPreview([])
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgForPreview.length - 1) % imgForPreview.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgForPreview.length)
          }
        />
      )}
      <VideoViewerModal
        show={show360Modal}
        onHide={() => setShow360Modal(false)}
        uri={roomSelected['360_link'] || null}
        name={roomSelected?.name_th}
      />
    </>
  );
};

export default TopCtaArea;
