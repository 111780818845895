import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import BookingModal from './../Booking/BookingModal';
import { numberFormat } from './../../utils/functions';

moment.locale('th')

const RoomDetailModal = ({ show, onHide = () => { }, room = {} }) => {
  const [showBookingModal, setShowBookingModal] = useState(false)

  useEffect(() => {
  }, [])

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{room?.name_th}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="price-table__item p-2 price-border mb-30 border-0">
            <div className="price-table__top d-flex justify-content-between mb-50">
              <span>{room?.suggestion_guest_info_th}</span>
            </div>
            <div className="price-table__content">
              <h2 className="price-table__price mb-15">{numberFormat(room?.total_price, true)} บาท</h2>
              <div className="price-table__list mb-55">
                {room?.short_info_th}
              </div>
              <div className="tp-purchase-btn" onClick={() => {
                onHide()
                setShowBookingModal(true)
              }}>
                จองทันที{" "}
                <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <BookingModal
        show={showBookingModal}
        onHide={(_ignore_reload) => {
          setShowBookingModal(null)
          if (!_ignore_reload) {
            window.location.reload()
          }
        }}
        date={null}
      />
    </>
  );
};

export default RoomDetailModal;
